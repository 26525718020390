@media only screen and (max-width: 1280px) {
}

@media only screen and (max-width: 1140px) {
}

@media only screen and (max-width: 1080px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 920px) {
}

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 768px) {
  .gallery__item {
    width: 33.333%;
  }
  .oneServ__outer:after {
    margin-top: 25%;
  }
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 480px) {
  .gallery__item {
    width: 50%;
  }
  .videoBox__outer {
    width: 100%;
  }
  .oneServ__outer:after {
    margin-top: 30%;
  }
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}
