@media only screen and (max-width: 1280px) {
  .footerMenu li {
    margin-right: 1.5rem;
  }
  .footerMenu li a {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 1140px) {
}

@media only screen and (max-width: 1080px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 920px) {
  .f1 {
    width: 100%;
    align-items: center;
  }
  .footer__logo {
    margin-bottom: 20px;
  }
  .f2 {
    width: 100%;
    align-items: center;
    padding: 10px 0;
  }
  .footerMenu__area {
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 480px) {
  .footerMenu__area {
    display: none;
  }
  .f3 {
    padding: 20px 0 10px;
  }
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}
