.page__wrapper {
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  flex: 1;
}

.page__area {
  position: relative;
  min-height: 100%;
  padding: 10px 0 20px;
}

.pageTitle {
  padding: 30px 0 10px;
  text-align: left;
}

.pageTitle h1 {
  margin: 0;
  color: var(--gold);
}

.page__outer {
  position: relative;
  min-height: 100%;
}

.page {
  min-height: 100%;
  margin: 10px 0;
  padding: 10px;
  background: var(--gold2);
  box-shadow: 0px 0px 10px 2px var(--gold);
  border-radius: 3px;
}

.innerBox {
  width: 100%;
  padding: 10px 0 10px;
  border-top: 2px solid #000;
  margin-top: 20px;
}

.gallery {
  margin: 0 -5px;
  display: flex;
  /*justify-content: space-around;*/
  flex-wrap: wrap;
}

.gallery__item {
  width: 16.666%;
  padding: 5px;
}

.gallery__item a {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.gallery__item a:after {
  content: "\e951";
  font-family: "icomoon";
  font-size: 2rem;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(211, 160, 109, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.5s;
}

.gallery__item a:hover:after {
  opacity: 1;
}

.gallery__item a img {
  display: block;
  width: 100%;
  transition: all 0.5s;
}

.gallery__item a:hover img {
  -webkit-transform: rotateZ(5deg) scale(1.1);
  -ms-transform: rotateZ(5deg) scale(1.1);
  transform: rotateZ(5deg) scale(1.1);
}

.videoBox__area {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  justify-content: center;
}

.videoBox__outer {
  padding: 5px;
  width: 50%;
}

.videoBox {
  position: relative;
}

/* .videoBox:hover {

} */

.videoBox a:after {
  content: "\e960";
  font-family: "icomoon";
  font-size: 3rem;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(211, 160, 109, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.5s;
}

.videoBox a:hover:after {
  opacity: 1;
}

.videoBox a {
  display: block;
}

.videoBox a img {
  display: block;
  width: 100%;
  margin-bottom: 5px;
}

.aboutMaster_inPage {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.aboutMaster_inPage span {
  margin: 10px 0;
}

.oneServ__area {
  width: 100%;
}

.oneServ__outer {
  width: 100%;
  position: relative;
  display: flex;
}

.oneServ__outer:after {
  content: "";
  margin-top: 20%;
  display: block;
}

.oneServ {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 3px;
  margin-bottom: 1rem;
  box-shadow: 0 0 10px 2px var(--gold);
  overflow: hidden;
}
.oneServ:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}

.oneServ h1 {
  color: #fff;
  margin: 0;
  position: relative;
}
