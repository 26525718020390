@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?e46r9c");
  src: url("../fonts/icomoon.eot?e46r9c#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?e46r9c") format("truetype"), url("../fonts/icomoon.woff?e46r9c") format("woff"),
    url("../fonts/icomoon.svg?e46r9c#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-anchor:before {
  content: "\e954";
}
.icon-chair:before {
  content: "\e955";
}
.icon-flower:before {
  content: "\e956";
}
.icon-heart:before {
  content: "\e957";
}
.icon-horse-tattoo-variant-facing-the-left:before {
  content: "\e959";
}
.icon-ink:before {
  content: "\e95a";
}
.icon-numb-spray:before {
  content: "\e95b";
}
.icon-swallow:before {
  content: "\e95c";
}
.icon-tattoo:before {
  content: "\e95d";
}
.icon-tattoo-machine:before {
  content: "\e95e";
}
.icon-leftss2:before {
  content: "\e952";
}
.icon-rightss2:before {
  content: "\e953";
}
.icon-like2fill:before {
  content: "\e94f";
}
.icon-like2:before {
  content: "\e950";
}
.icon-search2:before {
  content: "\e951";
}
.icon-bag:before {
  content: "\e94c";
}
.icon-left-end-arrow2:before {
  content: "\e94a";
}
.icon-right-end-arrow2:before {
  content: "\e94b";
}
.icon-left-end-arrow:before {
  content: "\e948";
}
.icon-right-end-arrow:before {
  content: "\e949";
}
.icon-left-and-right-arrow:before {
  content: "\e904";
}
.icon-left-and-right-arrows:before {
  content: "\e905";
}
.icon-left-and-right-arrows2:before {
  content: "\e906";
}
.icon-right-and-left-straight-arrows:before {
  content: "\e946";
}
.icon-transfer:before {
  content: "\e947";
}
.icon-brand:before {
  content: "\e900";
}
.icon-flyers:before {
  content: "\e902";
}
.icon-down:before {
  content: "\e901";
}
.icon-phone:before {
  content: "\e903";
}
.icon-marker1:before {
  content: "\e907";
}
.icon-time1:before {
  content: "\e908";
}
.icon-present1:before {
  content: "\e909";
}
.icon-avatar1:before {
  content: "\e90a";
}
.icon-search1:before {
  content: "\e90b";
}
.icon-favorit1:before {
  content: "\e90c";
}
.icon-favorit2:before {
  content: "\e90d";
}
.icon-cart1:before {
  content: "\e90e";
}
.icon-arr-right1:before {
  content: "\e90f";
}
.icon-more1:before {
  content: "\e910";
}
.icon-fb:before {
  content: "\e911";
}
.icon-ok:before {
  content: "\e912";
}
.icon-ig:before {
  content: "\e913";
}
.icon-arr-left1:before {
  content: "\e914";
}
.icon-close1:before {
  content: "\e915";
}
.icon-up1:before {
  content: "\e916";
}
.icon-vk:before {
  content: "\e917";
}
.icon-phone1:before {
  content: "\e918";
}
.icon-filter:before {
  content: "\e919";
}
.icon-plus:before {
  content: "\e91a";
}
.icon-search:before {
  content: "\e91b";
}
.icon-telegram:before {
  content: "\e91c";
}
.icon-viber:before {
  content: "\e91d";
}
.icon-watsapp:before {
  content: "\e91e";
}
.icon-pinterest-logo:before {
  content: "\e91f";
}
.icon-instagram2:before {
  content: "\e920";
}
.icon-vk2:before {
  content: "\e921";
}
.icon-odnoklassniki-logo:before {
  content: "\e922";
}
.icon-twitter-bird-logo:before {
  content: "\e923";
}
.icon-phone-call2:before {
  content: "\e924";
}
.icon-avatar:before {
  content: "\e925";
}
.icon-profile:before {
  content: "\e926";
}
.icon-ruler:before {
  content: "\e927";
}
.icon-shopping-cart2:before {
  content: "\e928";
}
.icon-circular-wall-clock:before {
  content: "\e929";
}
.icon-emai2l:before {
  content: "\e92a";
}
.icon-close:before {
  content: "\e92b";
}
.icon-shopping-cart:before {
  content: "\e92c";
}
.icon-youtube-logo:before {
  content: "\e92d";
}
.icon-next2:before {
  content: "\e92e";
}
.icon-shield-checked:before {
  content: "\e92f";
}
.icon-back2:before {
  content: "\e930";
}
.icon-eye:before {
  content: "\e931";
}
.icon-home:before {
  content: "\e932";
}
.icon-camera:before {
  content: "\e933";
}
.icon-file-text:before {
  content: "\e934";
}
.icon-profile1:before {
  content: "\e935";
}
.icon-files-empty:before {
  content: "\e936";
}
.icon-file-play:before {
  content: "\e937";
}
.icon-file-video:before {
  content: "\e938";
}
.icon-copy:before {
  content: "\e939";
}
.icon-filter2:before {
  content: "\e93a";
}
.icon-filter3:before {
  content: "\e93b";
}
.icon-sort:before {
  content: "\e93c";
}
.icon-sort2:before {
  content: "\e93d";
}
.icon-barcode:before {
  content: "\e93e";
}
.icon-qrcode:before {
  content: "\e93f";
}
.icon-image:before {
  content: "\e940";
}
.icon-images:before {
  content: "\e941";
}
.icon-connection:before {
  content: "\e942";
}
.icon-credit-card:before {
  content: "\e943";
}
.icon-phone2:before {
  content: "\e944";
}
.icon-phone-hang-up:before {
  content: "\e945";
}
.icon-history:before {
  content: "\e94d";
}
.icon-clock:before {
  content: "\e94e";
}
.icon-bubble2:before {
  content: "\e96e";
}
.icon-bubbles3:before {
  content: "\e96f";
}
.icon-bubbles4:before {
  content: "\e970";
}
.icon-lock:before {
  content: "\e98f";
}
.icon-unlocked:before {
  content: "\e990";
}
.icon-equalizer:before {
  content: "\e992";
}
.icon-equalizer2:before {
  content: "\e993";
}
.icon-cog:before {
  content: "\e994";
}
.icon-cogs:before {
  content: "\e995";
}
.icon-bug:before {
  content: "\e999";
}
.icon-stats-bars:before {
  content: "\e99c";
}
.icon-gift:before {
  content: "\e99f";
}
.icon-leaf:before {
  content: "\e9a4";
}
.icon-rocket:before {
  content: "\e9a5";
}
.icon-fire:before {
  content: "\e9a9";
}
.icon-bin:before {
  content: "\e9ac";
}
.icon-bin2:before {
  content: "\e9ad";
}
.icon-airplane:before {
  content: "\e9af";
}
.icon-truck:before {
  content: "\e9b0";
}
.icon-accessibility:before {
  content: "\e9b2";
}
.icon-clipboard:before {
  content: "\e9b8";
}
.icon-list-numbered:before {
  content: "\e9b9";
}
.icon-list1:before {
  content: "\e9ba";
}
.icon-list2:before {
  content: "\e9bb";
}
.icon-menu:before {
  content: "\e9bd";
}
.icon-menu2:before {
  content: "\e9be";
}
.icon-menu3:before {
  content: "\e9bf";
}
.icon-menu4:before {
  content: "\e9c0";
}
.icon-sun:before {
  content: "\e9d4";
}
.icon-contrast:before {
  content: "\e9d5";
}
.icon-brightness-contrast:before {
  content: "\e9d6";
}
.icon-star-empty:before {
  content: "\e9d7";
}
.icon-star-half:before {
  content: "\e9d8";
}
.icon-star-full:before {
  content: "\e9d9";
}
.icon-smile:before {
  content: "\e9e1";
}
.icon-smile2:before {
  content: "\e9e2";
}
.icon-sad:before {
  content: "\e9e5";
}
.icon-sad2:before {
  content: "\e9e6";
}
.icon-warning:before {
  content: "\ea07";
}
.icon-enter:before {
  content: "\ea13";
}
.icon-exit:before {
  content: "\ea14";
}
.icon-tab:before {
  content: "\ea45";
}
.icon-move-up:before {
  content: "\ea46";
}
.icon-move-down:before {
  content: "\ea47";
}
.icon-sort-alpha-asc:before {
  content: "\ea48";
}
.icon-sort-alpha-desc:before {
  content: "\ea49";
}
.icon-sort-amount-asc:before {
  content: "\ea4c";
}
.icon-sort-amount-desc:before {
  content: "\ea4d";
}
.icon-camera1:before {
  content: "\e95f";
}
.icon-play:before {
  content: "\e960";
}
.icon-film:before {
  content: "\e961";
}
.icon-video-camera:before {
  content: "\e962";
}
.icon-mobile:before {
  content: "\e958";
}
.icon-tree:before {
  content: "\e9bc";
}
.icon-man:before {
  content: "\e9dc";
}
.icon-woman:before {
  content: "\e9dd";
}
.icon-man-woman:before {
  content: "\e9de";
}
.icon-play2:before {
  content: "\ea15";
}
.icon-pause:before {
  content: "\ea16";
}
.icon-stop:before {
  content: "\ea17";
}
.icon-previous:before {
  content: "\ea18";
}
.icon-next:before {
  content: "\ea19";
}
.icon-backward:before {
  content: "\ea1a";
}
.icon-forward2:before {
  content: "\ea1b";
}
.icon-play3:before {
  content: "\ea1c";
}
.icon-pause2:before {
  content: "\ea1d";
}
.icon-stop2:before {
  content: "\ea1e";
}
.icon-backward2:before {
  content: "\ea1f";
}
.icon-forward3:before {
  content: "\ea20";
}
.icon-first:before {
  content: "\ea21";
}
.icon-last:before {
  content: "\ea22";
}
.icon-previous2:before {
  content: "\ea23";
}
.icon-next21:before {
  content: "\ea24";
}
.icon-arrow-up2:before {
  content: "\ea3a";
}
.icon-arrow-right2:before {
  content: "\ea3c";
}
.icon-arrow-down2:before {
  content: "\ea3e";
}
.icon-arrow-left2:before {
  content: "\ea40";
}
.icon-circle-up:before {
  content: "\ea41";
}
.icon-circle-right:before {
  content: "\ea42";
}
.icon-circle-down:before {
  content: "\ea43";
}
.icon-circle-left:before {
  content: "\ea44";
}
.icon-scissors:before {
  content: "\ea5a";
}
