.header__area {
  position: relative;
  background-color: var(--grafit2);
  background-color: #000;
}

.header__outer {
  position: relative;
}

.header {
  display: flex;
  padding: 20px 0;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* .imgCover {
  width: 100%;
  min-width: 1600px;
  margin: 0 auto;
  position: fixed;
  top: 0;
  z-index: 10;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: none;
  height: 100vh;
}

.videocover {
  width: 100%;
  min-width: 1600px;
  margin: 0 auto;
  position: fixed;
  top: 0;
  z-index: 10;
} */

.logo1 {
  width: 100%;
  max-width: 200px;
}

.logo1 a {
  text-decoration: none;
}

.logo1 img {
  display: block;
  width: 100%;
}

.topMenu__area {
  position: relative;
  display: block;
}

.topMenu {
  display: flex;
  list-style: none;
  align-items: center;
}

.topMenu li {
  display: flex;
  list-style: none;
  margin-right: 1.75rem;
}

.topMenu li:last-child {
  margin-right: 0;
}

.topMenu li a {
  display: block;
  text-decoration: none;
  position: relative;
  font-size: 1.25rem;
  color: #fff;
  transition: all 0.3s;
}

.topMenu li.active a,
.topMenu li a:hover {
  color: var(--gold);
}

.menuButton {
  background: var(--gold);
  border-radius: 2px;
  width: 32px;
  height: 32px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  display: none;
  z-index: 2000;
}

.menuButton span {
  display: block;
  position: absolute;
  height: 2px;
  width: 20px;
  background: #000;
  opacity: 1;
  left: 6px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.menuButton span:nth-child(1) {
  top: 6px;
}

.menuButton span:nth-child(2) {
  top: 15px;
}

.menuButton span:nth-child(3) {
  top: 24px;
}

.menuButton.open span:nth-child(1) {
  top: 15px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.menuButton.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.menuButton.open span:nth-child(3) {
  top: 15px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
