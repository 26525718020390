@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap");
@import "../../../node_modules/sweetalert/dist/sweetalert.css";

@import "../../../node_modules/swiper/css/swiper.css";
@import "../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css";

@import "reset.css";

@import "fonts.css";
@import "typorraphy.css";
@import "commons.css";

@import "xandercode.css";

@import "__header.css";
@import "__header-media.css";

/* @import "__slider1.css";
@import "__slider1-media.css"; */

@import "__footer.css";
@import "__footer-media.css";

@import "__page.css";
@import "__page-media.css";

@import "../chunks/portfolioCarousel/__portfolioCarousel.css";
@import "../chunks/portfolioCarousel/__portfolioCarousel-media.css";

@import "__portfolio.css";
@import "__portfolio-media.css";
/* @import "__breadcrumbs.css"; */

@import "__price.css";
@import "__price-media.css";

@import "main.css";
@import "media.css";
