:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #28a745;
  --bs-teal: #20c997;
  --bs-cyan: #17a2b8;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #28a745;
  --bs-info: #17a2b8;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #343a40;
  --gold-: #ffb500;
  --gold: #d3a06d;
  --gold2: rgba(211, 160, 109, 0.9);
  --grafit1: #1a1d25;
  --grafit2: rgba(26, 29, 37, 0.5);
}

.gold {
  color: var(--gold);
}

.white {
  color: #fff;
}

.black {
  color: #000;
}

.stop {
  overflow: hidden;
  padding-right: 0;
}

.clearfix::after {
  content: ""; /* Генерируем пустой элемент */
  clear: both; /* Отменяем обтекание*/
  display: block; /* Блочный элемент */
}

.flex1 {
  flex: 1;
}
