html,
body {
  color: #000;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #000;
}

.site__header {
  position: relative;
}

.site__center {
  position: relative;
}

.site__footer {
  position: relative;
  margin-top: auto;
}

.p1280 {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

.main1 {
  position: relative;
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-attachment: fixed;
}

.header__main {
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
}

.main1__sloganArea {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  top: 50%;
  position: absolute;
  z-index: 10;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

.slogan {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.slogan__row {
  display: flex;
}

.slogan__row span {
  display: flex;
  font-size: 2rem;
  color: #fff;
  text-transform: uppercase;
  margin-left: 1rem;
}

.slogan__row span.gold {
  color: var(--gold);
}

.slogan__rowTop span {
  font-size: 4.5rem;
}

.age {
  width: 100%;
  max-width: 1280px;
  margin: auto auto 2rem;
  position: relative;
}

.toBottom {
  position: absolute;
  left: 50%;
  bottom: 5%;
  z-index: 20;
  width: 32px;
  height: 32px;
  margin-left: -16px;
  -webkit-animation: b 1s infinite;
  animation: b 1s infinite;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toBottom i {
  font-size: 2rem;
  color: var(--gold);
}

@-webkit-keyframes b {
  0% {
    bottom: 20%;
  }
  50% {
    bottom: 40%;
  }
  to {
    bottom: 20%;
  }
}

@keyframes b {
  0% {
    bottom: 20%;
  }
  50% {
    bottom: 40%;
  }
  to {
    bottom: 20%;
  }
}

.jack__area {
  /* background-size: cover; */
  background-repeat: no-repeat;
  padding: 180px 0 120px;
}

.jack_quote {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.jack__text {
  color: black;
  font-weight: 700;
  font-size: 1.875rem;
  font-style: italic;
  margin-bottom: 2rem;
  text-align: center;
}

.jack__name {
  color: black;
  font-size: 1.5rem;
  font-weight: 300;
  font-style: italic;
  margin-left: auto;
}

.about__area {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}
.about__outer {
  position: relative;
  padding: 50px 0;
}

.about {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.about__left {
  width: 35%;
  display: flex;
  flex-direction: column;
}

.mainBlock__about {
  padding: 0 40px 0 50px;
}

.mainBlock__serv {
  padding: 0 40px 0 50px;
}

.mainBlock__title {
  display: flex;
  flex-direction: column;
}

.mainBlock__title h1 {
  font-size: 2.25rem;
  line-height: 1.3;
  font-weight: 700;
  text-transform: uppercase;
}

.mainBlock__title span {
  font-size: 2.25rem;
  line-height: 1.3;
  font-weight: 700;
  text-transform: uppercase;
}

.redmire_link {
  font-size: 0.875rem;
  font-weight: 300;
  text-transform: uppercase;
  text-decoration: none;
}
.redmire_link:hover {
  text-decoration: underline;
}

.about__right {
  width: 65%;
}

.about__content {
  display: flex;
  flex-direction: column;
}

.about__content span {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.8rem;
  margin: 15px 0;
}

.serv__area {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  padding: 50px 0;
}

.serv {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.serv__left {
  width: 35%;
  display: flex;
  flex-direction: column;
}

.mainBlock__info {
  margin-top: 50px;
  font-size: 1.125rem;
}

.serv__right {
  width: 65%;
}

.service {
  display: flex;
  flex-direction: column;
}

.service__main {
  padding: 0 0 0 30%;
}

.service__itemOuter {
  width: 100%;
  margin: 1rem 0;
}

.service__item {
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 180px;
  padding: 0 30px 0 50px;
  align-items: center;
  text-decoration: none;
  position: relative;
}

.service__item:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  transition: all 0.7s;
}
.service__item:hover:before {
  background: rgba(0, 0, 0, 0.5);
}

.service__item span {
  text-shadow: 0 3px 3.9px black;
  color: white;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
}

.studioIcon__area {
  /* background-size: cover; */
  background-repeat: no-repeat;
  width: 100%;
  padding: 20px 0;
}

.studioIcon__outer {
  position: relative;
}

.studioIcon {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.studioIcon__item {
  display: flex;
  align-items: center;
  width: 40%;
  margin: 2.5rem 0;
}

.studioIcon__item i {
  color: #fff;
  font-size: 5rem;
  margin: 0 1.5rem 0 0;
}

.studioIcon__item span {
  color: var(--gold);
  font-size: 1.5rem;
  line-height: 1.3;
  font-weight: 300;
  order: 1;
  font-weight: 500;
}

.studioIcon__item:nth-child(2n) {
  justify-content: flex-end;
}

.studioIcon__item:nth-child(2n) i {
  display: flex;
  order: 1;
  margin: 0 0 0 1.5rem;
}

.studioIcon__item:nth-child(2n) span {
  order: 0;
  text-align: right;
}

.studioIcon__title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  position: absolute;
  z-index: 1000;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

.studioIcon__title span {
  font-size: 2.25rem;
  font-weight: 700;
  text-transform: uppercase;
}

.portfolioCarousel__area {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  padding: 20px 0;
}

.portfolioCarousel__outer {
  position: relative;
}

.portfolioCarousel {
  width: 100%;
}

.portfolioCarousel__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.portfolioCarousel__titleLeft {
  display: flex;
}

.portfolioCarousel__titleLeft span {
  font-size: 2.25rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 10px;
}

.portfolioCarousel__titleRight {
  display: flex;
  text-decoration: none;
  color: #000;
  position: relative;
}
.portfolioCarousel__titleRight:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 1%;
  width: 98%;
  height: 2px;
  background: #000;
  opacity: 0;
  transition: all 0.3s;
}
.portfolioCarousel__titleRight:hover:after {
  opacity: 1;
}

.portfolioCarousel__titleRight span {
  font-size: 1.125rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-left: 10px;
}
.portfolioCarousel__titleRight span:first-child {
  margin: 0;
}

.contacts {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contacts__item {
  position: relative;
  margin-bottom: 20px;
}

.contacts__item a {
  display: flex;
  text-decoration: none;
  align-items: center;
  transition: all 0.3s;
}

.contacts__item a i {
  font-size: 2rem;
  margin-right: 15px;
  color: var(--gold);
}

.contacts__item a span {
  font-size: 1.5rem;
  color: var(--gold);
}

.contacts__item a:hover {
  transform: scale(1.2);
}

i.icon-viber {
  color: #8f5db7 !important;
}

i.icon-watsapp {
  color: #25d366 !important;
}

.contacts__item_big a i {
  font-size: 2.5rem;
}

.contacts__item_big a span {
  font-size: 1.75rem;
}
