@media only screen and (max-width: 1280px) {
}

@media only screen and (max-width: 1140px) {
}

@media only screen and (max-width: 1080px) {
}

@media only screen and (max-width: 1024px) {
  .portfolio__item a {
    border: 5px solid var(--gold);
  }
}

@media only screen and (max-width: 920px) {
  .portfolio__item {
    width: 25%;
  }
}

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 600px) {
  .portfolio__item {
    width: 33.333%;
  }
}

@media only screen and (max-width: 480px) {
  .portfolio__item {
    width: 50%;
  }
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
  .portfolio {
    margin: 0 -5px;
  }
  .portfolio__item {
    padding: 5px;
  }
}
