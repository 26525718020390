.portfolio__outer {
  position: relative;
}

.portfolio {
  display: flex;
  margin: 0 -10px;
  flex-wrap: wrap;
}

.portfolio__item {
  padding: 10px;
  width: 20%;
}

.portfolio__item a {
  display: block;
  text-decoration: none;
  border: 10px solid var(--gold);
  transition: all 0.4s;
  border-radius: 4px;
}
.portfolio__item a:hover {
  border: 10px solid #fff;
  box-shadow: 0 0 10px 2px var(--gold);
}

.portfolio__item a img {
  display: block;
  width: 100%;
  border-radius: 4px;
}
